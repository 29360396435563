/**
 * Do not edit directly
 * Generated on Wed, 02 Nov 2022 13:45:31 GMT
 */

:root {
  --fp-primitives-brand-sleep: #0e1438;
  --fp-primitives-brand-think: #cbc1f6;
  --fp-primitives-brand-go: #76fb8f;
  --fp-primitives-gray-0: #ffffff;
  --fp-primitives-gray-50: #f7f7f7;
  --fp-primitives-gray-100: #e2e2e2;
  --fp-primitives-gray-150: #d4d4d4;
  --fp-primitives-gray-200: #c6c6c6;
  --fp-primitives-gray-300: #a9a9a9;
  --fp-primitives-gray-400: #8d8d8d;
  --fp-primitives-gray-500: #707070;
  --fp-primitives-gray-600: #5a5a5a;
  --fp-primitives-gray-700: #444444;
  --fp-primitives-gray-800: #2d2d2d;
  --fp-primitives-gray-900: #161616;
  --fp-primitives-gray-1000: #000000;
  --fp-primitives-red-50: #fff2f0;
  --fp-primitives-red-100: #edd0ce;
  --fp-primitives-red-200: #e5a19d;
  --fp-primitives-red-300: #d9726c;
  --fp-primitives-red-400: #cc433b;
  --fp-primitives-red-500: #bf140a;
  --fp-primitives-red-600: #991008;
  --fp-primitives-red-700: #730c06;
  --fp-primitives-red-800: #4c0804;
  --fp-primitives-yellow-50: #fff8eb;
  --fp-primitives-yellow-100: #fff2d9;
  --fp-primitives-yellow-200: #fee5b5;
  --fp-primitives-yellow-300: #ffd582;
  --fp-primitives-yellow-400: #ffc759;
  --fp-primitives-yellow-500: #ffa617;
  --fp-primitives-yellow-600: #f28900;
  --fp-primitives-yellow-700: #ba5d00;
  --fp-primitives-yellow-800: #9b4e00;
  --fp-primitives-green-50: #e9f5f1;
  --fp-primitives-green-100: #cee6de;
  --fp-primitives-green-200: #9ecebe;
  --fp-primitives-green-300: #6db59d;
  --fp-primitives-green-400: #3d9d7d;
  --fp-primitives-green-500: #0c845c;
  --fp-primitives-green-600: #0a6a4a;
  --fp-primitives-green-700: #074f37;
  --fp-primitives-green-800: #053525;
  --fp-primitives-blue-50: #edf3fc;
  --fp-primitives-blue-100: #cee2f9;
  --fp-primitives-blue-200: #9ec5f3;
  --fp-primitives-blue-300: #6da7ee;
  --fp-primitives-blue-400: #3d8ae8;
  --fp-primitives-blue-500: #0c6de2;
  --fp-primitives-blue-600: #0a57b5;
  --fp-primitives-blue-700: #074188;
  --fp-primitives-blue-800: #052c5a;
  --fp-primitives-purple-50: #f5f2fc;
  --fp-primitives-purple-100: #ebe7fb;
  --fp-primitives-purple-200: #c3b6f3;
  --fp-primitives-purple-300: #9b85eb;
  --fp-primitives-purple-400: #7255e3;
  --fp-primitives-purple-500: #4a24db;
  --fp-primitives-purple-600: #3a1caa;
  --fp-primitives-purple-700: #29147a;
  --fp-primitives-purple-800: #190c49;
  --fp-primitives-darkblue-50: #e4e6ec;
  --fp-primitives-darkblue-100: #bbc0d1;
  --fp-primitives-darkblue-200: #9098b2;
  --fp-primitives-darkblue-300: #677194;
  --fp-primitives-darkblue-400: #4a5580;
  --fp-primitives-darkblue-500: #2c3b6d;
  --fp-primitives-darkblue-600: #273466;
  --fp-primitives-darkblue-700: #1f2c5b;
  --fp-primitives-darkblue-800: #18234f;
  --fp-primitives-darkblue-900: #0e1438;
  --fp-primitives-static-black: #000000;
  --fp-primitives-static-dim-gray: #444444;
  --fp-primitives-static-dark-gray: #707070;
  --fp-primitives-static-light-gray: #c6c6c6;
  --fp-primitives-static-white: #ffffff;
  --fp-primitives-static-sleep: #0e1438;
  --fp-primitives-static-think: #cbc1f6;
  --fp-primitives-static-go: #76fb8f;
  --fp-primitives-elevation-1: #ffffff;
  --fp-primitives-elevation-2: #ffffff;
  --fp-primitives-elevation-3: #ffffff;
  --fp-semantic-bg-primary: #ffffff;
  --fp-semantic-bg-secondary: #f7f7f7;
  --fp-semantic-bg-tertiary: #0e1438;
  --fp-semantic-bg-quaternary: #cbc1f6;
  --fp-semantic-bg-quinary: #76fb8f;
  --fp-semantic-bg-senary: #e2e2e2;
  --fp-semantic-bg-info: #edf3fc;
  --fp-semantic-bg-error: #fff2f0;
  --fp-semantic-bg-warning: #fff8eb;
  --fp-semantic-bg-success: #e9f5f1;
  --fp-semantic-bg-accent: #4a24db;
  --fp-semantic-bg-neutral: #f7f7f7;
  --fp-semantic-bg-surface1: #ffffff;
  --fp-semantic-bg-surface2: #ffffff;
  --fp-semantic-bg-surface3: #ffffff;
  --fp-semantic-text-primary: #000000;
  --fp-semantic-text-secondary: #2d2d2d;
  --fp-semantic-text-tertiary: #707070;
  --fp-semantic-text-quaternary: #8d8d8d;
  --fp-semantic-text-quinary: #ffffff;
  --fp-semantic-text-senary: #cbc1f6;
  --fp-semantic-text-septenary: #76fb8f;
  --fp-semantic-text-info: #0a57b5;
  --fp-semantic-text-error: #991008;
  --fp-semantic-text-warning: #9b4e00;
  --fp-semantic-text-success: #0a6a4a;
  --fp-semantic-text-accent: #4a24db;
  --fp-semantic-text-neutral: #2d2d2d;
  --fp-semantic-border-primary: #d4d4d4;
  --fp-semantic-border-secondary: #4a24db;
  --fp-semantic-border-tertiary: #e2e2e2;
  --fp-semantic-border-error: #bf140a;
  --fp-elevation-0: 0px 0px 0px 0px rgb(0, 0, 0);
  --fp-elevation-1: 0px 1px 4px 1px rgba(28, 28, 28, 0.04),
    0px 1px 1px rgba(28, 28, 28, 0.07),
    0px 1px 3px -4px rgba(189, 189, 189, 0.22);
  --fp-elevation-2: 0px 2px 5px 2px rgba(28, 28, 28, 0.04),
    0px 4px 5px 4px rgba(155, 155, 155, 0.04),
    0px 2px 20px 7px rgba(210, 210, 210, 0.08);
  --fp-elevation-3: 0px 4px 8px 7px rgba(230, 230, 230, 0.04),
    0px 2px 8px 2px rgba(50, 50, 50, 0.02),
    0px 5px 12px 5px rgba(28, 28, 28, 0.06),
    0px 1px 40px 6px rgba(28, 28, 28, 0.03);
  --fp-elevation-dialog: 0px 1px 8px 0px rgba(0, 0, 0, 0.1411764705882353);
  --fp-font-family-default: DM Sans;
  --fp-font-family-code: Source Code Pro;
  --fp-typography-display1: 600 60px/76px 'DM Sans';
  --fp-typography-display2: 600 40px/52px 'DM Sans';
  --fp-typography-display3: 600 28px/36px 'DM Sans';
  --fp-typography-display4: 400 21px/32px 'DM Sans';
  --fp-typography-heading1: 600 24px/32px 'DM Sans';
  --fp-typography-heading2: 600 21px/32px 'DM Sans';
  --fp-typography-heading3: 600 19px/28px 'DM Sans';
  --fp-typography-body1: 400 17px/28px 'DM Sans';
  --fp-typography-body2: 400 16px/24px 'DM Sans';
  --fp-typography-body3: 400 15px/20px 'DM Sans';
  --fp-typography-body4: 400 14px/20px 'DM Sans';
  --fp-typography-label1: 500 17px/28px 'DM Sans';
  --fp-typography-label2: 500 16px/24px 'DM Sans';
  --fp-typography-label3: 500 15px/20px 'DM Sans';
  --fp-typography-label4: 500 14px/20px 'DM Sans';
  --fp-typography-caption1: 500 13px/16px 'DM Sans';
  --fp-typography-caption2: 400 13px/16px 'DM Sans';
  --fp-typography-caption3: 500 12px/16px 'DM Sans';
  --fp-typography-caption4: 400 12px/16px 'DM Sans';
  --fp-typography-snippet1: 400 16px/24px 'Source Code Pro';
  --fp-typography-snippet2: 400 14px/20px 'Source Code Pro';
  --fp-typography-header-title: 500 16px/24px 'DM Sans';
  --fp-typography-body-title: 600 19px/28px 'DM Sans';
  --fp-typography-body-subtitle: 400 16px/24px 'DM Sans';
  --fp-typography-body-label-default: 500 16px/24px 'DM Sans';
  --fp-typography-body-label-compact: 500 15px/20px 'DM Sans';
  --fp-typography-body-body-default: 400 16px/24px 'DM Sans';
  --fp-typography-body-body-compact: 400 15px/20px 'DM Sans';
  --fp-typography-body-body-tiny: 400 14px/20px 'DM Sans';
  --fp-border-radius-full: 500px;
  --fp-border-radius-large: 16px;
  --fp-border-radius-default: 6px;
  --fp-border-radius-compact: 4px;
  --fp-border-radius-dialog: 6px;
  --fp-spacing-0: 0px;
  --fp-spacing-1: 2px;
  --fp-spacing-2: 4px;
  --fp-spacing-3: 8px;
  --fp-spacing-4: 12px;
  --fp-spacing-5: 16px;
  --fp-spacing-6: 20px;
  --fp-spacing-7: 24px;
  --fp-spacing-8: 32px;
  --fp-spacing-9: 40px;
  --fp-spacing-10: 64px;
  --fp-spacing-11: 96px;
  --fp-spacing-12: 128px;
  --fp-spacing-13: 156px;
  --fp-spacing-14: 192px;
  --fp-spacing-15: 256px;
  --fp-opacity-100: 0.04;
  --fp-opacity-200: 0.08;
  --fp-opacity-300: 0.12;
  --fp-opacity-400: 0.16;
  --fp-opacity-500: 0.24;
  --fp-opacity-600: 0.32;
  --fp-opacity-700: 0.48;
  --fp-opacity-800: 0.64;
  --fp-opacity-900: 0.8;
  --fp-opacity-1000: 1;
  --fp-opacity-transparent: 0;
  --fp-border-width-default: 1px;
  --fp-select-icon-inactive: #000000;
  --fp-select-icon-focus: #4a24db;
  --fp-select-icon-focus-error: #991008;
  --fp-phone-inputs-icon-inactive: #8d8d8d;
  --fp-phone-inputs-icon-focus: #4a24db;
  --fp-phone-inputs-icon-focus-error: #991008;

  --fp-link-color: #4a24db;

  --fp-link-button-default-initial-text: #4a24db;
  --fp-link-button-default-initial-icon: #4a24db;
  --fp-link-button-default-hover-text: #29147a;
  --fp-link-button-default-hover-icon: #29147a;
  --fp-link-button-default-active-text: #29147a;
  --fp-link-button-default-active-icon: #29147a;
  --fp-link-button-default-disabled-text: #190c4952;
  --fp-link-button-default-disabled-icon: #190c4952;
  --fp-link-button-destructive-initial-text: #991008;
  --fp-link-button-destructive-initial-icon: #991008;
  --fp-link-button-destructive-hover-text: #4c0804;
  --fp-link-button-destructive-hover-icon: #4c0804;
  --fp-link-button-destructive-active-text: #4c0804;
  --fp-link-button-destructive-active-icon: #4c0804;
  --fp-link-button-destructive-disabled-text: #4c080452;
  --fp-link-button-destructive-disabled-icon: #4c080452;
  --fp-link-button-sizing-default: 24px;
  --fp-link-button-sizing-compact: 20px;
  --fp-link-button-sizing-tiny: 20px;
  --fp-link-button-sizing-x-tiny: 16px;
  --fp-link-button-sizing-xx-tiny: 16px;
  --fp-link-button-typography-default: 500 16px/24px 'DM Sans';
  --fp-link-button-typography-compact: 500 15px/20px 'DM Sans';
  --fp-link-button-typography-tiny: 500 14px/20px 'DM Sans';
  --fp-link-button-typography-x-tiny: 500 13px/16px 'DM Sans';
  --fp-link-button-typography-xx-tiny: 500 12px/16px 'DM Sans';
  --fp-icon-button-border-width: 1px;
  --fp-icon-button-height-large: 40px;
  --fp-icon-button-height-default: 32px;
  --fp-icon-button-border-radius: 100px;
  --fp-icon-button-transparent-initial-bg: #ffffff00;
  --fp-icon-button-transparent-initial-icon: #000000;
  --fp-icon-button-transparent-initial-border: #e2e2e200;
  --fp-icon-button-transparent-hover-bg: #f7f7f7;
  --fp-icon-button-transparent-hover-icon: #000000;
  --fp-icon-button-transparent-hover-border: #e2e2e200;
  --fp-icon-button-transparent-active-bg: #e2e2e2;
  --fp-icon-button-transparent-active-icon: #000000;
  --fp-icon-button-transparent-active-border: #e2e2e200;
  --fp-icon-button-transparent-disabled-bg: #f7f7f7;
  --fp-icon-button-transparent-disabled-icon: #8d8d8d;
  --fp-icon-button-transparent-disabled-border: #e2e2e200;
  --fp-icon-button-elevation: 0px 0px 0px 0px rgb(0, 0, 0);
  --fp-icon-button-solid-initial-bg: #ffffff;
  --fp-icon-button-solid-initial-icon: #000000;
  --fp-icon-button-solid-initial-border: #e2e2e2;
  --fp-icon-button-solid-hover-bg: #f7f7f7;
  --fp-icon-button-solid-hover-icon: #000000;
  --fp-icon-button-solid-hover-border: #e2e2e2;
  --fp-icon-button-solid-active-bg: #e2e2e2;
  --fp-icon-button-solid-active-icon: #000000;
  --fp-icon-button-solid-active-border: #e2e2e2;
  --fp-icon-button-solid-disabled-bg: #f7f7f7;
  --fp-icon-button-solid-disabled-icon: #8d8d8d;
  --fp-icon-button-solid-disabled-border: #e2e2e2;
  --fp-google-button-initial-bg: #ffffff;
  --fp-google-button-initial-text: #000000;
  --fp-google-button-initial-border: #d4d4d4;
  --fp-google-button-hover-bg: #f7f7f7;
  --fp-google-button-hover-text: #000000;
  --fp-google-button-hover-border: #d4d4d4;
  --fp-google-button-active-bg: #f7f7f7;
  --fp-google-button-active-text: #000000;
  --fp-google-button-active-border: #d4d4d4;
  --fp-google-button-loading-bg: #ffffff;
  --fp-google-button-loading-icon: #000000;
  --fp-google-button-loading-border: #d4d4d4;
  --fp-google-button-height-large: 60px;
  --fp-google-button-height-default: 48px;
  --fp-google-button-height-compact: 40px;
  --fp-google-button-border-radius: 6px;
  --fp-google-button-spacing-paddings-horizontal-large: 24px;
  --fp-google-button-spacing-paddings-horizontal-default: 24px;
  --fp-google-button-spacing-paddings-horizontal-compact: 24px;
  --fp-google-button-typography-large: 500 17px/28px 'DM Sans';
  --fp-google-button-typography-default: 500 16px/24px 'DM Sans';
  --fp-google-button-typography-compact: 500 15px/20px 'DM Sans';
  --fp-button-border-width: 1px;
  --fp-button-elevation-initial: 0px 0px 0px 0px rgb(0, 0, 0);
  --fp-button-elevation-hover: 0px 0px 0px 0px rgb(0, 0, 0);
  --fp-button-elevation-active: 0px 0px 0px 0px rgb(0, 0, 0);
  --fp-fp-button-initial-bg: #0e1438;
  --fp-fp-button-initial-text: #ffffff;
  --fp-fp-button-initial-icon: #76fb8f;
  --fp-fp-button-hover-bg: #18234f;
  --fp-fp-button-hover-text: #ffffff;
  --fp-fp-button-hover-icon: #76fb8f;
  --fp-fp-button-active-bg: #18234f;
  --fp-fp-button-active-text: #ffffff;
  --fp-fp-button-active-icon: #76fb8f;
  --fp-fp-button-loading-bg: #0e1438;
  --fp-fp-button-loading-icon: #ffffff;
  --fp-fp-button-height: 48px;
  --fp-fp-button-border-radius: 6px;
  --fp-fp-button-spacing-paddings-horizontal: 24px;
  --fp-fp-button-typography: 500 17px/28px 'DM Sans';
  --fp-dialog-header-bg: #ffffff;
  --fp-dialog-header-text: #000000;
  --fp-dialog-header-icon: #000000;
  --fp-dialog-header-border: #e2e2e2;
  --fp-dialog-body-bg-primary: #ffffff;
  --fp-dialog-body-bg-secondary: #f7f7f7;
  --fp-dialog-body-text-primary: #000000;
  --fp-dialog-body-text-secondary: #2d2d2d;
  --fp-dialog-body-text-tertiary: #707070;
  --fp-dialog-footer-bg: #f7f7f7;
  --fp-dialog-footer-icon: #2d2d2d;
  --fp-dialog-footer-text: #2d2d2d;
  --fp-dialog-footer-border: #e2e2e2;
  --fp-date-range-input-color-top-label: #000000;
  --fp-date-range-input-color-hint: #707070;
  --fp-date-range-input-color-hint-error: #991008;
  --fp-date-range-input-color-input-content-selected-text: #ffffff;
  --fp-date-range-input-color-input-content-selected-bg: #4a24db;
  --fp-date-range-input-color-input-content-unselected-text: #000000;
  --fp-date-range-input-color-icon: #000000;
  --fp-confirmation-dialog-header-bg: #ffffff;
  --fp-confirmation-dialog-header-text: #000000;
  --fp-confirmation-dialog-header-icon: #000000;
  --fp-confirmation-dialog-header-border: #e2e2e2;
  --fp-confirmation-dialog-body-bg: #ffffff;
  --fp-confirmation-dialog-body-text: #2d2d2d;
  --fp-confirmation-dialog-footer-bg: #ffffff;
  --fp-confirmation-dialog-elevation: 0px 1px 8px 0px
    rgba(0, 0, 0, 0.1411764705882353);
  --fp-confirmation-dialog-border-radius: 6px;
  --fp-confirmation-dialog-typography-header-title: 500 16px/24px 'DM Sans';
  --fp-confirmation-dialog-typography-body-body-default: 400 16px/24px 'DM Sans';
  --fp-buttons-primary-initial-bg: #0e1438;
  --fp-buttons-primary-initial-text: #ffffff;
  --fp-buttons-primary-initial-border: #d4d4d400;
  --fp-buttons-primary-hover-bg: #18234f;
  --fp-buttons-primary-hover-text: #ffffff;
  --fp-buttons-primary-hover-border: #d4d4d400;
  --fp-buttons-primary-active-bg: #18234f;
  --fp-buttons-primary-active-text: #ffffff;
  --fp-buttons-primary-active-border: #d4d4d400;
  --fp-buttons-primary-disabled-bg: #f7f7f7;
  --fp-buttons-primary-disabled-text: #8d8d8d;
  --fp-buttons-primary-disabled-border: #d4d4d400;
  --fp-buttons-primary-loading-bg: #0e1438;
  --fp-buttons-primary-loading-icon: #ffffff;
  --fp-buttons-primary-loading-border: #d4d4d400;
  --fp-buttons-secondary-initial-bg: #ffffff;
  --fp-buttons-secondary-initial-text: #000000;
  --fp-buttons-secondary-initial-border: #d4d4d4;
  --fp-buttons-secondary-hover-bg: #f7f7f7;
  --fp-buttons-secondary-hover-text: #000000;
  --fp-buttons-secondary-hover-border: #d4d4d4;
  --fp-buttons-secondary-active-bg: #f7f7f7;
  --fp-buttons-secondary-active-text: #000000;
  --fp-buttons-secondary-active-border: #d4d4d4;
  --fp-buttons-secondary-disabled-bg: #ffffff;
  --fp-buttons-secondary-disabled-text: #8d8d8d;
  --fp-buttons-secondary-disabled-border: #e2e2e2;
  --fp-buttons-secondary-loading-bg: #ffffff;
  --fp-buttons-secondary-loading-icon: #000000;
  --fp-buttons-secondary-loading-border: #d4d4d4;
  --fp-buttons-height-large: 60px;
  --fp-buttons-height-default: 48px;
  --fp-buttons-height-compact: 40px;
  --fp-buttons-height-small: 32px;
  --fp-buttons-border-radius: 6px;
  --fp-buttons-spacing-paddings-horizontal-large: 24px;
  --fp-buttons-spacing-paddings-horizontal-default: 24px;
  --fp-buttons-spacing-paddings-horizontal-compact: 24px;
  --fp-buttons-spacing-paddings-horizontal-small: 12px;
  --fp-buttons-typography-large: 500 17px/28px 'DM Sans';
  --fp-buttons-typography-default: 500 16px/24px 'DM Sans';
  --fp-buttons-typography-compact: 500 15px/20px 'DM Sans';
  --fp-buttons-typography-small: 500 14px/20px 'DM Sans';
  --fp-bifrost-dialog-header-bg: #ffffff;
  --fp-bifrost-dialog-header-text: #000000;
  --fp-bifrost-dialog-header-icon: #000000;
  --fp-bifrost-dialog-header-border: #e2e2e2;
  --fp-bifrost-dialog-body-bg-primary: #ffffff;
  --fp-bifrost-dialog-body-bg-secondary: #f7f7f7;
  --fp-bifrost-dialog-body-text-primary: #000000;
  --fp-bifrost-dialog-body-text-secondary: #2d2d2d;
  --fp-bifrost-dialog-body-text-tertiary: #707070;
  --fp-bifrost-dialog-footer-bg: #f7f7f7;
  --fp-bifrost-dialog-footer-icon: #2d2d2d;
  --fp-bifrost-dialog-footer-text: #2d2d2d;
  --fp-bifrost-dialog-footer-border: #e2e2e2;
  --fp-bifrost-dialog-elevation: 0px 1px 4px 0px
    rgba(0, 0, 0, 0.12156862745098039);
  --fp-bifrost-dialog-border-radius: 6px;
  --fp-bifrost-dialog-typography-header-title: 500 16px/24px 'DM Sans';
  --fp-bifrost-dialog-typography-body-title: 600 19px/28px 'DM Sans';
  --fp-bifrost-dialog-typography-body-subtitle: 400 16px/24px 'DM Sans';
  --fp-bifrost-dialog-typography-body-label-default: 500 16px/24px 'DM Sans';
  --fp-bifrost-dialog-typography-body-label-compact: 500 15px/20px 'DM Sans';
  --fp-bifrost-dialog-typography-body-body-default: 400 16px/24px 'DM Sans';
  --fp-bifrost-dialog-typography-body-body-compact: 400 15px/20px 'DM Sans';
  --fp-bifrost-dialog-typography-body-body-tiny: 400 14px/20px 'DM Sans';
  --fp-bifrost-dialog-typography-footer-footer: 500 13px/16px 'DM Sans';
  --fp-base-inputs-base-bg: #ffffff;
  --fp-base-inputs-base-bg-hover: #ffffff;
  --fp-base-inputs-base-bg-disabled: #f7f7f7;
  --fp-base-inputs-base-border: #d4d4d4;
  --fp-base-inputs-base-border-hover: #8d8d8d;
  --fp-base-inputs-base-border-focus: #4a24db;
  --fp-base-inputs-base-border-error: #bf140a;
  --fp-base-inputs-base-border-error-hover: #730c06;
  --fp-base-inputs-base-border-disabled: #e2e2e2;
  --fp-base-inputs-base-input-text-placeholder: #8d8d8d;
  --fp-base-inputs-base-input-text: #000000;
  --fp-base-inputs-base-input-text-disabled: #8d8d8d;
  --fp-base-inputs-base-label: #000000;
  --fp-base-inputs-base-hint: #707070;
  --fp-base-inputs-base-hint-error: #991008;
  --fp-base-inputs-initial-bg: #ffffff;
  --fp-base-inputs-initial-border: #d4d4d4;
  --fp-base-inputs-initial-placeholder: #8d8d8d;
  --fp-base-inputs-initial-label: #000000;
  --fp-base-inputs-initial-hint: #707070;
  --fp-base-inputs-initial-error-bg: #ffffff;
  --fp-base-inputs-initial-error-border: #bf140a;
  --fp-base-inputs-initial-error-placeholder: #8d8d8d;
  --fp-base-inputs-initial-error-label: #000000;
  --fp-base-inputs-initial-error-hint: #991008;
  --fp-base-inputs-hover-bg: #ffffff;
  --fp-base-inputs-hover-border: #8d8d8d;
  --fp-base-inputs-hover-placeholder: #8d8d8d;
  --fp-base-inputs-hover-label: #000000;
  --fp-base-inputs-hover-hint: #707070;
  --fp-base-inputs-hover-error-bg: #ffffff;
  --fp-base-inputs-hover-error-border: #730c06;
  --fp-base-inputs-hover-error-placeholder: #8d8d8d;
  --fp-base-inputs-hover-error-label: #000000;
  --fp-base-inputs-hover-error-hint: #991008;
  --fp-base-inputs-focus-bg: #ffffff;
  --fp-base-inputs-focus-border: #4a24db;
  --fp-base-inputs-focus-placeholder: #8d8d8d;
  --fp-base-inputs-focus-label: #000000;
  --fp-base-inputs-focus-hint: #707070;
  --fp-base-inputs-focus-caret: #000000;
  --fp-base-inputs-focus-error-bg: #ffffff;
  --fp-base-inputs-focus-error-border: #bf140a;
  --fp-base-inputs-focus-error-placeholder: #8d8d8d;
  --fp-base-inputs-focus-error-label: #000000;
  --fp-base-inputs-focus-error-hint: #991008;
  --fp-base-inputs-focus-error-caret: #000000;
  --fp-base-inputs-focus-typing-bg: #ffffff;
  --fp-base-inputs-focus-typing-border: #4a24db;
  --fp-base-inputs-focus-typing-input-content: #000000;
  --fp-base-inputs-focus-typing-label: #000000;
  --fp-base-inputs-focus-typing-hint: #707070;
  --fp-base-inputs-focus-typing-caret: #000000;
  --fp-base-inputs-focus-typing-error-bg: #ffffff;
  --fp-base-inputs-focus-typing-error-border: #bf140a;
  --fp-base-inputs-focus-typing-error-input-content: #000000;
  --fp-base-inputs-focus-typing-error-label: #000000;
  --fp-base-inputs-focus-typing-error-hint: #991008;
  --fp-base-inputs-focus-typing-error-caret: #000000;
  --fp-base-inputs-filled-bg: #ffffff;
  --fp-base-inputs-filled-border: #d4d4d4;
  --fp-base-inputs-filled-input-content: #000000;
  --fp-base-inputs-filled-label: #000000;
  --fp-base-inputs-filled-hint: #707070;
  --fp-base-inputs-filled-error-bg: #ffffff;
  --fp-base-inputs-filled-error-border: #bf140a;
  --fp-base-inputs-filled-error-input-content: #000000;
  --fp-base-inputs-filled-error-label: #000000;
  --fp-base-inputs-filled-error-hint: #991008;
  --fp-base-inputs-disabled-bg: #f7f7f7;
  --fp-base-inputs-disabled-border: #e2e2e2;
  --fp-base-inputs-disabled-input-content: #8d8d8d;
  --fp-base-inputs-disabled-label: #000000;
  --fp-base-inputs-disabled-hint: #707070;
  --fp-base-inputs-height-text-area: 140px;
  --fp-base-inputs-height-default: 40px;
  --fp-base-inputs-height-compact: 32px;
  --fp-base-inputs-border-radius: 6px;
  --fp-base-inputs-spacing-paddings-horizontal-default: 16px;
  --fp-base-inputs-spacing-paddings-horizontal-compact: 16px;
  --fp-base-inputs-spacing-paddings-vertical-default: 10px;
  --fp-base-inputs-spacing-paddings-vertical-compact: 6px;
  --fp-base-inputs-typography-default-label: 500 14px/20px 'DM Sans';
  --fp-base-inputs-typography-default-input-content: 400 15px/20px 'DM Sans';
  --fp-base-inputs-typography-default-hint: 400 13px/16px 'DM Sans';
  --fp-base-inputs-typography-compact-label: 500 13px/16px 'DM Sans';
  --fp-base-inputs-typography-compact-input-content: 400 14px/20px 'DM Sans';
  --fp-base-inputs-typography-compact-hint: 400 13px/16px 'DM Sans';
  --fp-base-inputs-elevation-focus: 0px 0px 0px 4px
    rgba(74, 36, 219, 0.12156862745098039);
  --fp-base-inputs-elevation-focus-error: 0px 0px 0px 4px
    rgba(191, 20, 10, 0.12156862745098039);
  --fp-base-inputs-border-width: 1px;
  --fp-base-dropdown-base-bg-primary: #ffffff;
  --fp-base-dropdown-base-bg-primary-hover: #f7f7f7;
  --fp-base-dropdown-base-bg-address-footer: #f7f7f7;
  --fp-base-dropdown-base-text-primary: #000000;
  --fp-base-dropdown-base-text-secondary: #8d8d8d;
  --fp-base-dropdown-base-border: #e2e2e2;
  --fp-base-dropdown-base-icon-primary: #000000;
  --fp-base-dropdown-base-icon-secondary: #8d8d8d;
  --fp-base-dropdown-elevation: 0px 1px 8px 0px
    rgba(0, 0, 0, 0.1411764705882353);
  --fp-base-dropdown-spacing-items-2lines-paddings-horizontal: 16px;
  --fp-base-dropdown-spacing-items-2lines-paddings-vertical: 4px;
  --fp-base-dropdown-spacing-items-2lines-gap: 4px;
  --fp-base-dropdown-spacing-items-1line-paddings-horizontal: 16px;
  --fp-base-dropdown-spacing-items-1line-paddings-vertical: 8px;
  --fp-base-dropdown-spacing-items-1line-gap-child-container: 12px;
  --fp-base-dropdown-spacing-items-1line-gap-parent-container: 16px;
  --fp-base-dropdown-spacing-search-paddings-horizontal: 16px;
  --fp-base-dropdown-spacing-search-paddings-vertical: 12px;
  --fp-base-dropdown-spacing-search-gap-child-container: 12px;
  --fp-base-dropdown-spacing-search-gap-parent-container: 16px;
  --fp-base-dropdown-spacing-body-paddings-vertical: 8px;
  --fp-base-dropdown-spacing-body-items-gap: 4px;
  --fp-base-dropdown-spacing-body-sections-gap: 20px;
  --fp-base-dropdown-border-radius: 6px;
  --fp-token-set-order-0: global;
  --fp-token-set-order-1: light;
  --fp-token-set-order-2: dark;
  --fp-token-set-order-3: components/date-range-input;
  --fp-token-set-order-4: components/fp-button;
  --fp-token-set-order-5: components/buttons;
  --fp-token-set-order-6: components/google-button;
  --fp-token-set-order-7: components/link-button;
  --fp-token-set-order-8: components/icon-button;
  --fp-token-set-order-9: components/base-inputs;
  --fp-token-set-order-10: components/phone-inputs;
  --fp-token-set-order-11: components/select;
  --fp-token-set-order-12: components/base-dropdown;
  --fp-token-set-order-13: components/bifrost-dialog;
  --fp-token-set-order-14: components/confirmation-dialog;
  --fp-token-set-order-15: components/dialog;
  --fp-token-set-order-16: customization/ramp/global;
  --fp-token-set-order-17: customization/ramp/light;
  --fp-token-set-order-18: customization/ramp/base-inputs;
  --fp-token-set-order-19: customization/ramp/buttons;
  --fp-token-set-order-20: customization/ramp/bifrost-dialog;
}
